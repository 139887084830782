import React, {type SyntheticEvent} from 'react';
import {Overlay} from 'react-bootstrap';

import ProfilePopover from 'components/profile_popover';

import {popOverOverlayPosition} from 'utils/position_utils';

const SPACE_REQUIRED_FOR_POPOVER = 300;

type Props = {
    currentUserId: string;
    mentionName: string;
    teammateNameDisplay: string;
    children?: React.ReactNode;
    channelId?: string;
    disableHighlight?: boolean;
    disableGroupHighlight?: boolean;
    isRHS?: boolean;
    isMentionUserCurrentUser: boolean;
    mentionUserDisplayUsername: string;
    mentionUserProfilePicture: string;
    mentionUserId: string;
    mentionUserUsername?: string;
    groupFromMentionNameAllowReference?: boolean;
    groupFromMentionNameName?: string;
}

type State = {
    show: boolean;
    target?: HTMLAnchorElement;
    placement?: string;
}

export default class AtMention extends React.PureComponent<Props, State> {
    overlayRef: React.RefObject<HTMLAnchorElement>;

    static defaultProps: Partial<Props> = {
        isRHS: false,
        disableHighlight: false,
        disableGroupHighlight: false,
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            show: false,
        };

        this.overlayRef = React.createRef();
    }

    handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const targetBounds = this.overlayRef.current?.getBoundingClientRect();

        if (targetBounds) {
            const placement = popOverOverlayPosition(targetBounds, window.innerHeight, SPACE_REQUIRED_FOR_POPOVER);
            this.setState({target: e.target as HTMLAnchorElement, show: !this.state.show, placement});
        }
    }

    hideOverlay = (e?: SyntheticEvent) => {
        if (e?.target === this.overlayRef?.current) {
            return;
        }
        this.setState({show: false});
    }

    render() {
        if (!this.props.disableGroupHighlight && !this.props.mentionUserUsername) {
            if (this.props.groupFromMentionNameAllowReference) {
                return <span className='group-mention-link'>{'@' + this.props.groupFromMentionNameName}</span>;
            }
        }

        if (!this.props.mentionUserUsername) {
            return <React.Fragment>{this.props.children}</React.Fragment>;
        }

        const suffix = this.props.mentionName.substring((this.props.mentionUserUsername || '').length);

        let className = 'mention-link';

        if (!this.props.disableHighlight && this.props.isMentionUserCurrentUser) {
            className += ' mention--highlight';
        }

        return (
            <span>
                <Overlay
                    placement={this.state.placement}
                    show={this.state.show}
                    target={this.state.target}
                    rootClose={true}
                    onHide={this.hideOverlay}
                >
                    <ProfilePopover
                        userId={this.props.mentionUserId}
                        src={this.props.mentionUserProfilePicture}
                        isRHS={this.props.isRHS}
                        hide={this.hideOverlay}
                        channelId={this.props.channelId}
                    />
                </Overlay>
                <a
                    className={className}
                    onClick={this.handleClick}
                    ref={this.overlayRef}
                >
                    {'@' + this.props.mentionUserDisplayUsername}
                </a>
                {suffix}
            </span>
        );
    }
}
