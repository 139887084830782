import React from 'react';

import {useAppDispatch} from 'stores/redux_store';

import {useDraftsStorage} from './storage';

import {receivedInitialDrafts} from './logic/slice';
import {type Draft, type Drafts} from './types';

export function WithDrafts() {
    const dispatch = useAppDispatch();

    const onDraftsInit = React.useCallback(
        (drafts) => {
            if (drafts) {
                const draftsMap = Object.entries(drafts) as Array<[key: keyof Drafts, value: Draft]>;

                dispatch(
                    receivedInitialDrafts(
                        draftsMap.map(([key, value]) => ({
                            key,
                            value,
                        })),
                    ),
                );
            } else {
                dispatch(receivedInitialDrafts({} as any));
            }
        },
        [dispatch],
    );

    useDraftsStorage(onDraftsInit);

    return null;
}
