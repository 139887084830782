import {createSelector} from '@reduxjs/toolkit';
import isEqual from '@tinkoff/utils/is/equal';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';

import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {type RootState} from 'stores/redux_store';
import {getAllChannels, getMyChannelMemberships} from 'mattermost-redux/selectors/entities/channels';
import {getAllPosts} from 'mattermost-redux/selectors/entities/posts';
import {type ChannelDraft, type ThreadDraft} from '../types';

import {Posts} from 'mattermost-redux/constants';

import {type GlobalState} from 'types/store';

import {isChannelArchived} from 'features/channels/utils/is_channel_archived';

import {selectSelf, channelsDraftsAdapter, threadsDraftsAdapter} from './slice';
import {makeEmptyDraft} from './utils';

const {selectAll: selectAllChannelDrafts, selectById: selectChannelDraftByChannelId} =
    channelsDraftsAdapter.getSelectors<RootState>((state) => selectSelf(state).channelsDrafts);

const {selectAll: selectAllThreadDrafts, selectById: selectThreadDraftByRootPostId} =
    threadsDraftsAdapter.getSelectors<RootState>((state) => selectSelf(state).threadsDrafts);

/**
 * Получить id не загруженных постов, в обсуждении которых создан черновик
 */
export const getPostIdsForThreadDrafts = createSelector(
    getAllChannels,
    getMyChannelMemberships,
    getAllPosts,
    selectAllThreadDrafts,
    (channels, myChannelMemberships, posts, drafts) => drafts.reduce((postIds: Array<typeof draft['draftId']>, draft) => {
        if (draft.channelId) {
            const channel = channels[draft.channelId];

            // эта проверка нужна по двум причинам:
            // 1. если юзер больше не состоит в канале, не нужно пытаться загрузить пост для черновика
            // 2. если юзера снова добавили в канал, селектор должен среагировать на обновления списка каналов и показывать посты для загрузки
            if (!channel || !myChannelMemberships[channel.id] || isChannelArchived(channel)) {
                return postIds;
            }
        }

        if (!posts[draft.draftId]) {
            postIds.push(draft.draftId);
        }

        return postIds;
    }, []),
    {
        memoizeOptions: {
            resultEqualityCheck: isEqual,
        },
    },
);

/**
 * @deprecated используйте useChannelDraft/useThreadDraft для получения черновика
 */
export const getThreadDraft = (state: GlobalState, id: string) => {
    const teamId = getCurrentTeamId(state);
    const userId = getCurrentUserId(state);
    const draft = selectThreadDraftByRootPostId(state, id);
    const emptyDraft = makeEmptyDraft({draftId: id, userId, teamId, type: 'thread'});
    return draft || emptyDraft;
};

export const getTeamAndUserChannelDrafts = createSelector(
    getAllChannels,
    getMyChannelMemberships,
    getCurrentTeamId,
    getCurrentUserId,
    selectAllChannelDrafts,
    (channels, myChannelMemberships, currentTeamId, currentUserId, channelDrafts) => {
        return channelDrafts
            .reduce((result, draft) => {
                const channel = channels[draft.draftId];
                if (!channel || !myChannelMemberships[channel.id] || isChannelArchived(channel)) {
                    return result;
                }
                if (draft.teamId !== currentTeamId) {
                    return result;
                }
                if (draft.userId !== currentUserId) {
                    return result;
                }
                result.push({...draft, channel, type: 'channel'});
                return result;
            }, [] as ChannelDraft[]);
    },
);

export const getTeamAndUserThreadDrafts = createSelector(
    getAllPosts,
    getMyChannelMemberships,
    getAllChannels,
    getCurrentTeamId,
    getCurrentUserId,
    selectAllThreadDrafts,
    (posts, myChannelMemberships, channels, currentTeamId, currentUserId, threadDrafts) => {
        return threadDrafts
            .reduce((result, draft) => {
                const post = posts[draft.draftId];
                if (!post || post.state === Posts.POST_DELETED) {
                    return result;
                }
                const channel = channels[post.channel_id];
                if (!channel || !myChannelMemberships[channel.id] || isChannelArchived(channel)) {
                    return result;
                }
                if (draft.teamId !== currentTeamId) {
                    return result;
                }
                if (draft.userId !== currentUserId) {
                    return result;
                }
                result.push({...draft, channel, post, type: 'thread'});
                return result;
            }, [] as ThreadDraft[]);
    },
);

export const getTeamAndUserThreadAndChannelDrafts = createSelector(
    getTeamAndUserChannelDrafts,
    getTeamAndUserThreadDrafts,
    (channelDrafts, threadDrafts) => {
        return ([] as Array<ThreadDraft | ChannelDraft>).concat(channelDrafts).concat(threadDrafts);
    },
);

export const getTeamAndUserThreadAndChannelDraftsSorted = createSelector(
    getTeamAndUserThreadAndChannelDrafts,
    (teamDrafts) => teamDrafts.concat([]).sort((prevDraft, draft) => (prevDraft.timestamp < draft.timestamp ? 1 : -1)),
);

export const getTotalNumberOfTeamDrafts = createSelector(
    getTeamAndUserThreadAndChannelDrafts,
    (teamDrafts) => teamDrafts.length,
);

export {selectChannelDraftByChannelId, selectThreadDraftByRootPostId};

export const getDraftsInit = createSelector(selectSelf, (state) => state.init);
